<template>
    <div class="selectPatient-container">
        <div class="selectPatient-list"> 
            <div class="selectPatient-item"  v-for="(item,i) in patientList" :key="i" @click="checkHandle(i, item)">
                <div>{{item.name}}</div>
                <div>{{item.sex}} / {{item.age}} / {{item.illType}}</div>
                <!-- <div> -->
                    <van-icon v-if="item.ischeck" name="success" color="#4DC600" size="15" />
                <!-- </div> -->
            </div>
        </div>
        <div class="btn">
            <van-button size="large" color="#0060C2" @click="createForm()">{{type === 1 ? '确定' : recordId ? '提交匹配' : '下一步'}}</van-button>
            <div class="create-newPatient" @click="createNewPatient">创建新的患者</div>
        </div>
    </div>
</template>
<script>
import { Icon ,  Button, Toast,Dialog  } from 'vant'
import PatientApi from '@api/patient/patientApi'
import {toPath} from '@/utils/common'
import { type } from 'os';
export default {
    components: {
        [Icon.name]: Icon,    
        [Button.name]: Button
    },
    data() {
        return {
            type: 1,            // 1 : 选择 2：关联
            patientList:[],
            checkInfo: {},
            recordId: '',
            isFlag: false
        }
    },
    created() {
        const { type,recordId} = this.$route.query
        // console.log('created',type,recordId)
        Object.assign(this, {
            type: Number(type || 1),
            recordId: recordId || ''
        })
        document.title = type == 2 ? '关联一位患者' : '选择一位患者'
        // 患者列表
        PatientApi.inquiryList().then(res => {
            // const { list } = res.data
            this.patientList = res.data
        })
    },
    methods:{
        // 创建新的患者
        createNewPatient() {
            const { type } = this
            const { projectId , projectName, recordId, ddMemberId } = this.$route.query
            console.log("push to select id:---"+ddMemberId);
            type === 1 ? this.$router.push({
                    path: '/createPatient',
                    query: {projectId: projectId || '' , projectName: projectName || '' , ddMemberId: ddMemberId || ''}
                }) : this.$router.push({
                    path: recordId ? '/crePatientAfterUpload' : 'crePatientBeforeAnswer',
                    query: recordId ? { recordId } : {}
                })
            
        },
        // 选择操作
        checkHandle(index,data) {
            const { patientList } = this
            this.patientList = patientList.map((item, i) => {
                item.ischeck = i === index || false
                return item
            })
            this.checkInfo = { inquiryId:data.id, inquiryName: data.name, illId: data.illId, status: data.status }
        },
        // 提交报名
        createForm() {
            const { type, checkInfo, isFlag } = this
            const { projectId , projectName, recordId, ddMemberId } = this.$route.query
            const { inquiryId, inquiryName, illId, status } = checkInfo
            if(!inquiryId) return Toast('请选择一位患者');
            if(isFlag) return
            if(type === 1) {
                // this.$router.replace({
                //     path: '/apply',
                //     query: {projectId , projectName, inquiryId, inquiryName}
                // }) 
                toPath(this, {
                    path: '/apply',
                    query: {projectId , projectName, inquiryId, inquiryName, ddMemberId: ddMemberId || ''}
                }, '', 'replace')
                this.isFlag = false
            } else {
                // 上传病例有recodeId提交试配，否则跳转到回答问题页面
                if(recordId) {
                    // 调用匹配接口
                    let data={inquiryId:inquiryId,id:recordId}
                    PatientApi.confirmInquiry(data).then(res => {
                        // this.$router.replace({
                        //     path: '/patientMatchList',
                        //     query: { inquiryId }
                        // }) 
                        toPath(this, {
                            path: '/patientMatchList',
                            query: { inquiryId }
                        },`/pages/match/index/index?inquiryId=${inquiryId}`)
                        this.isFlag = false
                    }).catch(err => {
                        this.isFlag = false
                        Toast(err.msg)
                    })
                } else {
                    this.isFlag = false
                    // this.$router.push({
                    //     path: '/question',
                    //     query: {inquiryId, illId}
                    // })
                    toPath(this, {
                        path: '/question',
                        query: {inquiryId, illId}
                    })
                    // status === 1 ? this.$router.push({
                    //     path: '/question',
                    //     query: {inquiryId, illId}
                    // }) : Dialog.alert({
                        //     message: '该癌种下的问题暂未开启'
                        // }).then(() => {
                        //     this.$router.replace({
                        //         path: '/patientMatchList',
                        //         query: { }
                        //     }) 
                        // });
                }
            }
            
        }
    }
}
</script>

<style lang="less"  scoped="scoped">
.selectPatient-container {
    min-height: 100vh;
    background: #F7F7F7;
    padding-top: 8px;
    .selectPatient-list {
        .selectPatient-item {
            background: #fff;
            display: flex;
            align-items: center;
            padding:15px;
            border-bottom: 1px solid #D5D5D5;
            div:nth-of-type(1) {
                font-size:16px;
                font-weight:500;
                color:rgba(42,42,46,1);
                margin-right: 15px;
                // line-height: 1;
            }
            div:nth-of-type(2) {
                font-size:14px;
                color:rgba(130,130,132,1);
                flex-grow: 1;
            }
            div:nth-of-type(3) {
                font-size:15px;
            }
            &:nth-last-of-type(1) {
                border-bottom: none;
            }
        }
    }
    .btn {
        width: 100%;
        padding: 50px 40px;
        button{
            height:50px;
            border-radius:10px;
            font-size: 18px;
            font-weight:500;
        }
        .create-newPatient {
            margin-top: 30px;
            font-size:16px;
            color:rgba(0,96,194,1);
            text-align: center;
        }
    }
}
</style>
